@import 'src/scss/variables';
@import 'src/scss/mixins/text';
@import 'src/scss/mixins/card';

.new-agreement-documents {
  @include card();
  gap: 24px;
  padding: 20px;
  position: relative;
}

.new-agreement-documents__header {
  display: flex;
  justify-content: space-between;
}

.new-agreement-documents__title {
  @include textBodyBig-medium();
  margin: 0;
  padding: 0;
}

.new-agreement-documents__button {
  width: 160px;
}
