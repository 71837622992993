@import 'src/scss/variables';

.thumbs-button {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0) 100%);
  border-radius: 2px 0px 0px 2px;
  font-size: 0;
  height: 64px;
  left: 3px;
  line-height: 0;
  position: absolute;
  top: 3px;
  transition: all $transition-time ease-in;
  width: 20px;
  z-index: 3;

  .thumbs-button__icon {
    color: $color-black-5;
    left: 0;
  }
}

.thumbs-button_disabled {
  opacity: 0;
  z-index: -1;
}

.thumbs-button_next {
  left: auto;
  right: 3px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.thumbs-button_short {
  background: transparent;
  height: 40px;
  left: 32px;
  top: 50%;
  transform: translateY(-24px);
  width: 40px;

  .thumbs-button__icon {
    color: $color-black-20;
    height: 40px;
    width: 40px;
  }
}

.thumbs-button__icon {
  transform: rotate(90deg);
}

.thumbs-button_short.thumbs-button_next {
  left: auto;
  right: 32px;
  transform: translateY(-24px) matrix(-1, 0, 0, 1, 0, 0);
}
