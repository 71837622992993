@import 'src/scss/variables';
@import 'src/scss/mixins/card';
@import 'src/scss/mixins/text';

.chat {
  @include card();
  grid-template-rows: max-content 356px max-content;
  overflow: hidden;
  position: relative;

  .loader_position_overlay {
    border-radius: 4px;
  }
}

.chat_disabled {
  grid-column: 1 / -1;
  grid-template-rows: max-content 356px;
}

.chat__title {
  @include textBodyBig-medium();
  background-color: $color-black-0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  display: grid;
  gap: 13px;
  grid-auto-flow: column;
  justify-content: start;
  margin: 0;
  padding: 12px 20px;
  position: relative;
  z-index: 2;
}

.chat__title-gray {
  color: $color-border-gray-hover;
  font-weight: $font-weight-light;
}

.chat__messages-wrapper {
  align-content: start;
  background: $color-black-5;
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  overflow: auto;
  padding: 20px;
}
