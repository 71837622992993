@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.dropbox-menu {
  background-color: $color-black-0;
  border-radius: 4px;
  box-shadow: $popup-box-shadow;
  box-sizing: border-box;
  max-height: 230px;
  overflow: auto;
  padding: 8px 12px;
  position: absolute;
  right: 0;
  top: 52px;
  width: 324px;
  z-index: 11;
}

.dropbox-menu__list,
.dropbox-menu__list-nested {
  display: grid;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropbox-menu__list-section {
  margin: 0;
  padding: 0;
}

.dropbox-menu__list-section:not(.dropbox-menu__list-section_last) {
  border-bottom: 1px solid $color-black-25;
  padding-bottom: 8px;
}

.dropbox-menu_position_background {
  left: 50%;
  max-height: none;
  padding-top: 52px;
  right: auto;
  top: -10px;
  transform: translateX(-50%);
  width: calc(100% + 24px);

  .dropbox-item__button {
    gap: 12px;
  }
}
