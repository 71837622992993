@import 'src/scss/variables';
@import 'src/scss/mixins/focus';
@import 'src/scss/mixins/text';

.document-attachment {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: max-content 1fr;
  justify-self: start;
  text-decoration: none;
  transition: opacity $transition-time;
  @include focus();
}

.document-attachment_uploadable {
  cursor: default;
  padding-bottom: 4px;
  padding-top: 4px;
}

.document-attachment__icon {
  align-items: center;
  background-color: rgba(172, 230, 253, 0.4);
  border-radius: 24px;
  color: $color-theme-main;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.document-attachment__file-name {
  @include textBodySmall();
  word-break: break-word;
}
