@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.pre-order-status-badge {
  @include textCaption();
  background-color: rgba($color-status-default, 0.12);
  border: 1px solid $color-status-default;
  border-radius: 2px;
  color: $color-status-default;
  display: inline-block;
  padding: 1px 5px;
  white-space: nowrap;
}

.pre-order-status-badge_draft {
  background-color: rgba($color-preorder-status-draft, 0.12);
  border-color: $color-preorder-status-draft;
  color: $color-preorder-status-draft;
}

.pre-order-status-badge_won {
  background-color: rgba($color-preorder-status-won, 0.12);
  border-color: $color-preorder-status-won;
  color: $color-preorder-status-won;
}

.pre-order-status-badge_waiting {
  background-color: rgba($color-preorder-status-waiting, 0.12);
  border-color: $color-preorder-status-waiting;
  color: $color-preorder-status-waiting;
}

.pre-order-status-badge_confirming {
  background-color: rgba($color-preorder-status-confirming, 0.12);
  border-color: $color-preorder-status-confirming;
  color: $color-preorder-status-confirming;
}

.pre-order-status-badge_lost {
  background-color: rgba($color-preorder-status-lost, 0.12);
  border-color: $color-preorder-status-lost;
  color: $color-preorder-status-lost;
}
