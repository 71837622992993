.alert-block {
  display: grid;
  gap: 12px;
  max-height: calc(100vh - 32px);
  overflow: auto;
  position: absolute;
  right: 20px;
  top: 16px;
  width: 410px;
  z-index: 115;
}
