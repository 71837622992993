@import 'src/scss/variables';

.thumbs {
  box-sizing: border-box;
  max-width: 714px;
  overflow: hidden;
  position: relative;
}

.thumbs__wrapper {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 3px;
  transition: transform $transition-time;
  width: 100%;
}

.thumbs_short {
  .thumb-item_last {
    padding-right: 0;
  }
}
