@import 'src/scss/variables';
@import 'src/scss/mixins/card';
@import 'src/scss/mixins/focus';
@import 'src/scss/mixins/text';

@mixin line($bottom: 9px) {
  position: relative;

  &::before {
    background-color: $color-black-20;
    bottom: $bottom;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 928px;
  }
}

.new-agreement-table {
  @include card();
  gap: 11px;
  padding: 18px 0 13px;
}

.new-agreement-table__title {
  @include textBodyBig-medium();
  margin: 0;
  padding: 0 20px;
}

.new-agreement-table__table-body {
  display: grid;
  gap: 4px;
}

.new-agreement-table__table-row {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: 80px 1fr 88px 52px 88px 20px;
  padding: 10px 20px 7px;
}

.new-agreement-table__table-row_package {
  padding-bottom: 30px;
  padding-top: 7px;
  @include line();
}

.new-agreement-table__table-row_additional {
  padding-bottom: 7px;
  padding-top: 7px;

  .service-cell {
    gap: 0;
  }
}

.new-agreement-table__table-row_added {
  background-color: $color-row-added;
  cursor: pointer;
  transition: background-color $transition-time;
  @include focus();

  &:hover {
    background-color: $color-row-added-hover;
  }
}

.new-agreement-table__table-row_deleted {
  background-color: $color-row-deleted;

  .new-agreement-table__table-cell_calculated-price,
  .new-agreement-table__table-cell_price,
  .new-agreement-table__table-cell_sku,
  .service-cell__name {
    text-decoration: line-through;
  }
}

.new-agreement-table__table-row_head {
  padding-bottom: 22px;
  @include line(5px);
}

.new-agreement-table__table-row_total {
  padding-top: 15px;
}

.new-agreement-table__table-head-cell {
  @include textBodySmall();
  color: $color-black-50;
  padding: 0;
  text-align: left;
}

.new-agreement-table__table-head-cell_right {
  text-align: right;
}

.new-agreement-table__table-cell {
  @include textBodySmall();
  padding: 0;
  word-break: break-word;
}

.new-agreement-table__table-cell_right {
  text-align: right;
}

.new-agreement-table__table-cell_service {
  grid-column: 2 / -2;
}

.new-agreement-table__table-cell-subtitle {
  @include textBody-medium();
  grid-column: 1 / -1;
  padding: 3px 0 7px;
}

.new-agreement-table__delete-icon {
  color: $color-alert;
}

.new-agreement-table__table-cell-total {
  @include textBody();
  grid-column: 1 / -1;
  padding: 0;
  text-align: right;
}

.new-agreement-table__total-value {
  box-sizing: border-box;
  display: inline-block;
  font-weight: $font-weight-medium;
  min-width: 137px;
  padding-left: 18px;
  text-align: left;
}
