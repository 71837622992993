@import 'src/scss/variables';
@import '../mixins/text';

.table {
  border-collapse: collapse;
  border-spacing: 0;
  display: grid;
}

.table__head-cell {
  @include textBodySmall();
  color: $color-black-50;
  padding: 0;
  text-align: left;
}

.table__cell {
  @include textBodySmall();
  padding: 0;
}

.table__cell_package {
  @include textBody();
}

.table__cell_text {
  padding-right: 24px;
}

.table__cell_date {
  color: $color-black-50;
}
