@import 'src/scss/variables';
@import 'src/scss/mixins/card';
@import 'src/scss/mixins/text';

.order-table {
  @include card();
  gap: 35px;
  grid-column: 1 / -1;
  padding: 20px 10px 18px 20px;
}

.order-table__title {
  @include textBodyBig-medium();
  margin: 0;
  padding: 0;
}

.order-table__table {
  gap: 25px;
}

.order-table__table_extra {
  gap: 21px;
}

.order-table__body {
  display: grid;
  gap: 12px;
}

.order-table__row {
  align-items: center;
  display: grid;
  grid-template-columns: 104px 1fr 128px 96px 76px 76px 64px 82px;
}

.order-table__row_package {
  padding-bottom: 22px;
  position: relative;

  &::after {
    background-color: $color-black-20;
    bottom: 10px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 10px;
  }
}

.order-table__total-row {
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px 0 6px;
  position: relative;

  &::before {
    background-color: $color-black-20;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 10px;
    top: 5px;
  }
}

.order-table__total-cell {
  padding: 0 20px 0 0;
  text-align: right;
}

.order-table__total-wrapper {
  display: inline-grid;
  gap: 10px;
  grid-template-columns: max-content minmax(87px, max-content);
}

.order-table__total-text {
  @include textBody();
}

.order-table__total-text_medium {
  font-weight: $font-weight-medium;
}
