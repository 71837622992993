@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.order-header {
  align-items: center;
  background: $color-black-0;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  height: $page-header-height;
  padding: 10px 32px;
}

.order-header__info {
  display: grid;
  gap: 8px;
  grid-template-rows: 32px 16px;
  justify-items: start;
  min-width: calc(
    (100vw - 587px) / 2
  ); // половина незанятого (табами/паддингами/гапами) пространства
}

.order-header__info-no {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
  white-space: nowrap;
}

.order-header__no {
  @include textH3-medium();
  margin: 0;
  padding: 0;
}

.order-header__info-meta {
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
}

.order-header__owner {
  @include textCaption-medium();
}

.order-header__date {
  @include textCaption();
  border-left: 1px solid $color-black-20;
  color: $color-black-50;
  padding-left: 12px;
}
