@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.label {
  @include textBodySmall();
}

.label_invalid {
  color: $color-red;
}

.label_flattened {
  @include textCaption();
}