@import 'src/scss/variables';
@import 'src/scss/mixins/card';
@import 'src/scss/mixins/text';

.order-card {
  @include card();
  align-content: start;
  gap: 20px;
  padding: 21px 20px 19px;
}

.order-card__title {
  @include textBodyBig-medium();
  margin: 0;
  padding: 0;
}

.order-card__row {
  display: grid;
  gap: 4px 12px;
  grid-template-columns: 20px 1fr;
}

.order-card__text {
  @include textBodySmall();
  grid-column: 2 / 3;
}

.order-card__text_gray {
  @include textCaption();
  color: $color-black-50;
  min-height: 20px;
}

.order-card__icon {
  color: $color-theme-main;
  line-height: 0;
}

.order-card_placement {
  grid-column: 1 / -1;
}
