@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.main-page {
  margin: 0;
  padding: 0;
}

.main-page__content {
  display: grid;
  gap: 40px;
  grid-template-columns: 600px;
  grid-template-rows: 40px 48px;
  justify-content: center;
  padding-top: 164px;
  position: relative;
}

.main-page__title {
  @include textH2-light();
  justify-self: center;
  margin: 0;
  padding: 0;
}

@media screen and (max-height: 800px) {
  .main-page__content {
    padding-top: 80px;
  }
}
