@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.dropbox {
  position: relative;
}

.dropbox__toggle {
  position: relative;
  z-index: 12;
}
