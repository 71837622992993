@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.empty-data {
  align-content: start;
  display: grid;
  justify-items: center;
  padding-top: 200px;
}

.empty-data__text {
  @include textBody();
  color: $color-caption;
  margin: 0;
}

.empty-data__icon {
  color: $color-theme-ultra-light;
}
