@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.button-icon {
  align-items: center;
  background: none;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: $color-black-75;
  cursor: pointer;
  display: grid;
  height: 20px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  width: 20px;

  svg {
    height: 20px;
    width: 20px;
  }

  &:focus:not(.button-icon_disabled) {
    box-shadow: $button-focus;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(.button-icon_disabled) {
    box-shadow: $button-focus;
  }
}

.button-icon_reset_link {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.button-icon_color_theme {
  color: $color-theme-main;
}

.button-icon_color_black {
  color: $color-black;
}

.button-icon_color_gray {
  color: $color-black-50;
}

.button-icon_color_red {
  color: $color-alert;
}

.button-icon_disabled {
  color: $color-black-20;
  cursor: default;
  pointer-events: none;
}
