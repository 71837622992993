@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.orders-table {
  align-content: start;
  border: 1px solid $color-border-gray;
  border-collapse: collapse;
  border-radius: 4px;
  border-spacing: 0;
  box-sizing: border-box;
  gap: 0;
  display: grid;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.orders-table__head {
  z-index: 10;
}

.orders-table__head-row {
  background: $color-black-5;
  box-shadow: $box-shadow;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 120px;
  justify-items: start;
  padding: 12px 20px;
}

.orders-table__head-cell {
  @include textBodySmall();
  color: $color-black-50;
  line-height: 24px;
  padding: 0;
}

.orders-table__body {
  align-content: start;
  box-sizing: border-box;
  gap: 0;
  max-height: calc(100vh - 264px);
  overflow: auto;
}

.orders-table__body-row {
  align-items: center;
  border-bottom: 1px solid $color-border-gray;
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 120px;
  padding: 8px 20px 7px;
  transition: background-color ease-in-out $transition-time;

  &:hover {
    background: $color-black-5;
  }
}

.orders-table__body-row_last {
  border-bottom: none;
}

.orders-table__body-cell {
  @include textBodySmall();
  display: grid;
  gap: 4px;
  padding: 0;
}

.orders-table__body-cell_light {
  @include textCaption();
  color: $color-black-50;
}

.orders-table__icon {
  color: $color-theme-main;
}

.orders-table__body-cell_icon {
  grid-template-columns: 1fr 20px;
}
