@import 'src/scss/variables.scss';
@import 'src/scss/mixins/text.scss';

.message-field {
  background: $color-black-5;
  border-radius: 2px;
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  padding: 8px;
}

.message-field__text {
  @include textBodySmall();
  color: $color-black-50;
  text-align: center;
}

.message-field__text_medium {
  font-weight: $font-weight-medium;
}

.message-field__text_error {
  color: $color-alert;
}
