@import '../variables';

.app {
  min-height: 100vh;
  position: relative;
}

.app__shadow {
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all ease-in-out $transition-time;
  z-index: 99;
}

.app__shadow_visible {
  background: $color-black-0;
  opacity: 0.75;
  pointer-events: all;
}
