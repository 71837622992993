@import 'src/scss/variables';
@import 'src/scss/mixins/card';
@import 'src/scss/mixins/text';

.agreement-table {
  @include card();
  gap: 11px;
  padding: 18px 0 13px;
}

.agreement-table__title {
  @include textBodyBig-medium();
  margin: 0;
  padding: 0 20px;
}

.agreement-table__table-body {
  display: grid;
  gap: 1px;
}

.agreement-table__table-row {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: 80px 1fr 88px 52px 88px;
  padding: 10px 20px;
}

.agreement-table__table-row_first-item {
  padding-top: 22px;
}

.agreement-table__table-row_package {
  background-color: $color-black-5;
  padding-bottom: 11px;
  padding-top: 13px;
}

.agreement-table__table-row_additional {
  padding-bottom: 8px;
  padding-top: 8px;
}

.agreement-table__table-row_head {
  padding-bottom: 6px;
}

.agreement-table__table-head-cell {
  @include textBodySmall();
  color: $color-black-50;
  padding: 0;
  text-align: left;
}

.agreement-table__table-head-cell_right {
  text-align: right;
}

.agreement-table__table-cell {
  @include textBodySmall();
  padding: 0;
  word-break: break-word;
}

.agreement-table__table-cell_right {
  text-align: right;
}

.agreement-table__table-cell_service {
  grid-column: 2 / -1;
}

.agreement-table__table-cell-subtitle {
  @include textBody-medium();
  grid-column: 1 / -1;
  padding: 19px 0 7px;
  position: relative;

  &::before {
    background-color: $color-black-20;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 928px;
  }
}
