@import 'src/scss/variables';

@mixin focus {
  outline: none;

  &:focus {
    box-shadow: $button-focus;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: $button-focus;
  }
}
