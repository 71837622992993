@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.document-view-section {
  align-content: start;
  display: grid;
  gap: 22px;
}

.document-view-section__title {
  @include textBodySmall();
  color: $color-black-50;
  margin: 0;
  padding-top: 2px;
}
