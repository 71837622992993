@import 'src/scss/variables';

// H1
@mixin textH1 {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-h1;
  font-weight: $font-weight-regular;
  line-height: 60px;
}

@mixin textH1-light {
  @include textH1();
  font-weight: $font-weight-light;
}

@mixin textH1-medium {
  @include textH1();
  font-weight: $font-weight-medium;
}

@mixin textH1-bold {
  @include textH1();
  font-weight: $font-weight-bold;
}

// H2
@mixin textH2 {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-h2;
  font-weight: $font-weight-regular;
  letter-spacing: 0.25px;
  line-height: 40px;
}

@mixin textH2-light {
  @include textH2();
  font-weight: $font-weight-light;
}

@mixin textH2-medium {
  @include textH2();
  font-weight: $font-weight-medium;
}

@mixin textH2-bold {
  @include textH2();
  font-weight: $font-weight-bold;
}

// H3
@mixin textH3 {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-h3;
  font-weight: $font-weight-regular;
  line-height: 32px;
}

@mixin textH3-light {
  @include textH3();
  font-weight: $font-weight-light;
}

@mixin textH3-medium {
  @include textH3();
  font-weight: $font-weight-medium;
}

@mixin textH3-bold {
  @include textH3();
  font-weight: $font-weight-bold;
}

// BODY
@mixin textBody {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-regular;
  font-weight: $font-weight-regular;
  letter-spacing: 0.44px;
  line-height: 24px;
}

@mixin textBody-light {
  @include textBody();
  font-weight: $font-weight-light;
}

@mixin textBody-medium {
  @include textBody();
  font-weight: $font-weight-medium;
}

@mixin textBody-bold {
  @include textBody();
  font-weight: $font-weight-bold;
}

// BODY SMALL
@mixin textBodySmall {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  letter-spacing: 0.25px;
  line-height: 20px;
}

@mixin textBodySmall-light {
  @include textBodySmall();
  font-weight: $font-weight-light;
}

@mixin textBodySmall-medium {
  @include textBody();
  font-weight: $font-weight-medium;
}

@mixin textBodySmall-bold {
  @include textBody();
  font-weight: $font-weight-bold;
}

// BODY BIG
@mixin textBodyBig {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-big;
  font-weight: $font-weight-regular;
  letter-spacing: 0.2px;
  line-height: 28px;
}

@mixin textBodyBig-light {
  @include textBodyBig();
  font-weight: $font-weight-light;
}

@mixin textBodyBig-medium {
  @include textBodyBig();
  font-weight: $font-weight-medium;
}

@mixin textBodyBig-bold {
  @include textBodyBig();
  font-weight: $font-weight-bold;
}

// BUTTON
@mixin textButton {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-regular;
  font-weight: $font-weight-medium;
  letter-spacing: 0.5px;
  line-height: 24px;
}

@mixin textButton-flattened {
  @include textButton();
  font-size: $font-size-small;
  line-height: 16px;
}

// CAPTION
@mixin textCaption {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-tiny;
  font-weight: $font-weight-regular;
  letter-spacing: 0.4px;
  line-height: 16px;
}

@mixin textCaption-light {
  @include textCaption();
  font-weight: $font-weight-light;
}

@mixin textCaption-medium {
  @include textCaption();
  font-weight: $font-weight-medium;
}

@mixin textCaption-bold {
  @include textCaption();
  font-weight: $font-weight-bold;
}
