@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.uploaded-file {
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: 20px 1fr 400px 20px;
  justify-content: start;
}

.uploaded-file__name {
  @include textBodySmall();
  margin: 0;
  word-break: break-word;
}

.uploaded-file__icon {
  color: $color-theme-main;
  height: 20px;
  width: 20px;
}

.uploaded-file_location_popup {
  border: 1px solid $color-black-10;
  border-radius: 4px;
  gap: 10px;
  grid-template-columns: 20px 1fr 20px;
  grid-template-areas:
    'fileIcon fileName deleteButton'
    'select select select';
  padding: 12px 16px;

  .uploaded-file__select {
    grid-area: select;
  }

  .uploaded-file__name {
    grid-area: fileName;
  }

  .uploaded-file__icon {
    grid-area: fileIcon;
  }

  .uploaded-file__delete-btn {
    color: $color-alert;
    grid-area: deleteButton;
  }
}
