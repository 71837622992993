@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.agreement-page__main {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 24px;
  grid-template-columns: $page-content-width;
  height: calc(100vh - #{$page-header-height});
  justify-content: center;
  overflow: auto;
  padding-bottom: 60px;
  padding-top: 40px;
}
