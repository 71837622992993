@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.order-search-result-popup {
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  display: grid;
  gap: 20px;
  box-shadow: $popup-box-shadow;
  left: 50%;
  padding: 2px 0 20px;
  position: absolute;
  top: calc(100% + 12px);
  transform: translateX(-50%);
  width: 920px;
}

.order-search-result-popup_empty {
  @include textBody();
  color: $color-caption;
  padding: 20px 16px 16px;
  text-align: center;
}

.order-search-result-popup__button {
  justify-self: center;
}
