@import 'src/scss/variables';
@import 'src/scss/mixins/text';
@import 'src/scss/mixins/card';

.payment-page-tabs {
  @include card();
  padding: 20px;
}

.payment-page-tabs__header {
  border-bottom: 1px solid $color-black-10;
  display: grid;
  gap: 18px;
  padding-bottom: 18px;
}

.payment-page-tabs__title {
  @include textBody-medium();
  margin: 0;
}

.payment-page-tabs__options {
  display: flex;
  gap: 36px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.payment-page-tabs__option {
  margin: 0;
  padding: 0;
}

.payment-page-tabs__view {
  padding: 20px;
}

.payment-page-tabs__text {
  @include textBodySmall();
  margin: 0;
  text-align: center;
}

.payment-page-tabs__text_strong {
  @include textBody-medium();
  letter-spacing: 0.15px;
}

.payment-page-tabs__view-qr {
  display: grid;
  gap: 24px;
  justify-items: center;
  padding-top: 20px;
}

.payment-page-tabs__view-link {
  display: grid;
  gap: 32px;
  justify-items: center;
  padding-bottom: 20px;
  padding-top: 40px;
}

.payment-page-tabs__view-link-actions {
  display: grid;
  gap: 16px;
  width: 460px;
}

.payment-page-tabs__link {
  align-items: center;
  border: 1px solid $color-black-20;
  border-radius: 4px;
  display: grid;
  gap: 8px;
  grid-template-columns: auto 20px;
  justify-content: space-between;
  padding: 9px 12px;
}

.payment-page-tabs__link-text {
  @include textBody();
  color: $color-theme-dark;
  text-decoration: none;
  word-break: break-word;
}

.payment-page-tabs__link-button {
  color: $color-theme-dark;

  &:hover {
    color: lighten($color-theme-dark, 8%);
  }

  &:active {
    color: darken($color-theme-dark, 8%);
  }
}
