@import './focus.scss';

@mixin image-button {
  background-position: center;
  background-size: cover;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  @include focus();
}
