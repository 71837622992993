@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.login-page {
  align-items: start;
  box-sizing: border-box;
  display: grid;
  gap: 40px;
  grid-template-rows: 1fr max-content;
  height: 100vh;
  margin: 0 auto;
  padding: 200px 0 32px;
  width: 400px;

  @media screen and (max-height: 900px) {
    padding-top: calc(100vh - 700px);
  }

  @media screen and (max-height: 750px) {
    padding-top: 50px;
  }
}

.login-page__main {
  display: grid;
  gap: 80px;
  justify-items: center;
}

.login-page__footer {
  display: grid;
  gap: 16px;
  justify-items: center;
}

.login-page__controls-wrapper {
  display: grid;
  gap: 40px;
  width: 100%;
}

.login-page__phone {
  @include textBody-light();
  color: $color-black-50;
}

.login-page__version {
  @include textCaption();
  color: $color-black-20;
}

.login-page__input-wrapper {
  display: grid;
  gap: 20px;
}

.login-page__buttons-wrapper {
  align-items: center;
  display: grid;
  gap: 18px;
  grid-auto-flow: column;
  justify-content: start;
}

.login-page__divider {
  background-color: $color-black-10;
  height: 16px;
  width: 1px;
}

.login-page__submit-button-wrapper {
  display: grid;
  gap: 16px;
}
