@import 'src/scss/variables';
@import 'src/scss/mixins/text.scss';

.image-viewer {
  align-content: end;
  background: $color-black-75;
  bottom: 0;
  box-sizing: border-box;
  display: grid;
  gap: 30px;
  justify-items: center;
  left: 0;
  margin: 0;
  padding: 32px 32px 47px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all $transition-time;
  z-index: 110;
}

.image-viewer__close-button {
  color: $color-black-20;
  height: 30px;
  position: absolute;
  right: 29px;
  top: 29px;
  width: 30px;

  .image-viewer__close-icon {
    height: 30px;
    width: 30px;
  }
}

.image-viewer__title {
  @include textBodySmall();
  box-sizing: border-box;
  color: $color-black-5;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  padding: 0 100px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.image-viewer__image {
  display: block;
  height: calc(100vh - 294px);
  max-width: calc(100vw - 200px);
  object-fit: cover;
  user-select: none;
}
