@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.select {
  position: relative;
  width: 100%;
}

.select__button {
  @include textBodySmall();
  align-items: center;
  background: $color-black-5;
  border: 1px solid $color-border-gray;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 13px 11px;
  position: relative;
  text-align: left;
  transition: border-color $transition-time ease-in-out;
  width: 100%;
}

.select__value {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select__dropbox {
  background-color: $color-black-0;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  max-height: 230px;
  overflow: auto;
  padding: 8px 12px;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 11;
}

.select__dropbox-wrapper {
  display: grid;
  gap: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.select__dropbox-item {
  margin: 0;
  padding: 0;
}

.select__dropbox-item-button {
  @include textBodySmall();
  background-color: $color-black-0;
  border: none;
  margin: 0;
  line-height: 20px;
  outline: none;
  padding: 10px;
  text-align: left;
  width: 100%;
}

.select__dropbox-item-button:not(.select__dropbox-item-button_active) {
  cursor: pointer;
}

.select__dropbox-item-button:hover,
.select__dropbox-item-button:focus,
.select__dropbox-item-button_active {
  background: rgba($color: $color-theme-main, $alpha: 0.1);
  border-radius: 4px;
}

.select__dropbox-item-button_empty {
  padding-bottom: 20px;
  padding-top: 20px;
}

.select__arrow {
  transition: transform $transition-time;
}

.select_open .select__arrow {
  transform: rotate(180deg);
}

.select:hover:not(.select_disabled) .select__button {
  border-color: $color-border-gray-hover;
}

.select:focus-within:not(.select_disabled) .select__button {
  border-color: transparent;
  box-shadow: 0 0 0 2px $color-outlined;
}

.select_disabled .select__button {
  border: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.04);
  color: $color-black-20;
  cursor: default;
  padding-bottom: 14px;
  padding-top: 14px;
}

.select_disabled .select__value {
  color: $color-black-20;
}

.select_flattened {
  .select__button {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .select__dropbox {
    max-height: 150px;
    top: 34px;
  }
}
