@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.tab {
  @include textBodySmall();
  background-color: $color-black-5;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.4px;
  margin: 0;
  outline: none;
  padding: 9px 12px 7px;
  position: relative;
  transition: all ease-in-out $transition-time;

  &:hover:not(:disabled) {
    background-color: $color-tab-hover;
  }

  &:focus:not(:disabled) {
    box-shadow: $button-focus;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:disabled) {
    box-shadow: $button-focus;
  }

  &:active:not(:disabled) {
    background-color: $color-theme-background;
  }
}

.tab_active {
  background: $color-theme-main;
  color: $color-black-5;

  &:hover:not(:disabled) {
    background-color: $color-theme-accent;
  }

  &:active:not(:disabled) {
    background-color: $color-theme-dark;
  }
}

.tab_disabled {
  background: $color-black-5;
  color: $color-black-20;
  cursor: default;
}

.tab_with-icon {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  padding-left: 11px;
}

.tab__icon {
  line-height: 0;
}
