@import 'src/scss/variables';
@import 'src/scss/mixins/focus';
@import 'src/scss/mixins/text';

.comment {
  align-content: start;
  display: grid;
  gap: 10px 8px;
  grid-template-columns: max-content 1fr;
}

.comment__user {
  @include textCaption();
}

.comment__date {
  @include textCaption();
  color: $color-border-gray-hover;
}

.comment__content {
  background: $color-black-0;
  border-radius: 4px;
  display: grid;
  gap: 11px;
  grid-column: 1 / -1;
  padding: 16px;
  position: relative;
}

.comment__message {
  @include textBodySmall();
  margin: 0;
  padding: 0;
}

.comment__images-wrapper {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 215px);
}

.comment__images-wrapper_wide {
  grid-template-columns: repeat(4, 1fr);
}

.comment__image {
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 140px;
  max-width: 215px;
  object-fit: cover;
  width: 100%;
  @include focus();
}

.comment__documents-wrapper {
  display: grid;
  gap: 16px;
  justify-content: start;
}

.comment__triangle-icon {
  color: $color-black-0;
  left: 17px;
  position: absolute;
  top: -8px;
}
