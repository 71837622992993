@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.header {
  padding: 14px 26px 14px 32px;
}

.header__sign-out-button {
  @include textCaption();
  height: 48px;
  padding-left: 52px;
  padding-right: 52px;
}

.header__sign-out-button .button__icon {
  left: initial;
  right: 16px;
  width: 20px;
}

.header__sign-out-icon {
  color: $color-theme-main;
  transition: color ease-in-out $transition-time;
}

.header__sign-out-button:hover {
  .header__sign-out-icon {
    color: $color-theme-dark;
  }
}

.header_extended {
  box-shadow: $box-shadow;
  display: grid;
  gap: 18px;
  grid-template-columns: max-content 1fr 168px;
}
