@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.input {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: $input-height;
  position: relative;
}

.input__field {
  @include textBodySmall();
  align-items: center;
  background: $color-black-5;
  border: 1px solid $color-border-gray;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  outline: none;
  padding: 0 11px;
  transition: border-color $transition-time ease-in-out;
  width: 100%;
}

.input__field:not(.input__field_disabled):hover {
  border-color: $color-border-gray-hover;
}

.input__field:not(.input__field_disabled):focus-within {
  border-color: transparent;
  box-shadow: 0 0 0 2px $color-input-outline;
}

.input__field.input__field_disabled {
  color: $color-black-20;
}

.input_label {
  gap: 4px;
  grid-template-rows: 20px $input-height;
}

.input_label.input_flattened {
  grid-template-rows: 16px $input-flattened-height;
}

.input_flattened {
  grid-template-rows: $input-flattened-height;
}

.input_invalid {
  .input__field:not(.input__field_disabled) {
    border-color: $color-alert;
    color: $color-alert;
  }
}

.input_icon .input__field {
  padding-right: 43px;
}

.input__icon {
  bottom: 14px;
  color: $color-text;
  height: 20px;
  right: 12px;
  position: absolute;
  width: 20px;
}

.input_search {
  .input__field {
    padding-right: 48px;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

.input_multi {
  grid-template-rows: 1fr;
}

.input_multi .input__placeholder {
  top: 0;
}

.input_multi.input_label {
  grid-template-rows: 20px 1fr;

  .input__placeholder {
    top: 24px;
  }
}

.input_multi textarea {
  @include textBodySmall();
  background: $color-black-5;
  border: 1px solid $color-border-gray;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  min-height: 56px;
  outline: none;
  padding: 11px 11px 19px;
  position: relative;
  resize: vertical;
  transition: border-color $transition-time ease-in-out;
  width: 100%;
}

textarea::-webkit-resizer {
  display: none;
}

.input__resize-icon {
  bottom: 0;
  color: $color-text;
  pointer-events: none;
  position: absolute;
  right: 4px;
}

.input__mask-text-wrapper {
  bottom: 12px;
  left: 12px;
  pointer-events: none;
  position: absolute;
}

.input.input_custom-mask-text .input__field {
  caret-color: $color-text;
  color: transparent;
}

.input__mask-text {
  @include textBodySmall();
  color: $color-black-50;
}

.input__mask-text_black {
  color: $color-text;
}

.input_disabled {
  .input__field {
    border: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.04);
  }

  textarea,
  .input__resize-icon {
    border-color: transparent;
    color: $color-black-20;
  }
}

.input_disabled.input_mask,
.input_disabled.input_custom-mask-text {
  .input__mask-text,
  .input__mask-text_black {
    color: $color-black-20;
  }
}

.input__clear-icon {
  cursor: pointer;
}
