@import 'src/scss/variables';
@import 'src/scss/mixins/card';
@import 'src/scss/mixins/text';

.history-card {
  @include card();
  gap: 35px;
  padding: 20px 20px 27px;
}

.history-card__title {
  @include textBodyBig-medium();
  margin: 0;
  padding: 0;
}

.history-card__table {
  gap: 22px;
}

.history-card__body {
  display: grid;
  gap: 16px;
}

.history-card__row {
  align-items: center;
  display: grid;
  grid-template-columns: 176px 202px 1fr;
}

.history-card__date {
  box-sizing: border-box;
  display: inline-block;
  min-width: 95px;
  padding-left: 3px;
  padding-right: 14px;
  position: relative;

  &:before {
    background-color: $color-black-20;
    content: '';
    height: 15px;
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
}
