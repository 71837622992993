@import 'src/scss/mixins/text';

.popup {
  align-content: start;
  background: $color-black-0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: $popup-box-shadow;
  display: grid;
  gap: 12px;
  grid-template-rows: max-content max-content 1fr;
  left: 50%;
  padding: 20px;
  position: fixed;
  top: -100%;
  transform: translate(-50%, -50%);
  transition: top ease-in-out $popup-transition-time;
  width: 400px;
  z-index: 100;
}

.popup__title {
  @include textBodyBig();
}

.popup__text {
  @include textBodySmall();
  align-items: center;
  display: flex;
  min-height: 24px;
}

.popup__actions {
  display: grid;
  gap: 12px;

  .button_borderless {
    color: $color-theme-main;
  }
}

.popup_visible {
  top: 50%;
}

.popup_info {
  .popup__text {
    min-height: 40px;
  }
}

.popup_upload {
  gap: 20px;
  max-height: 100vh;
  overflow: auto;
  width: 500px;
}

.popup__files {
  align-content: start;
  display: grid;
  gap: 20px;
}
