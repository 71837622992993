@import 'src/scss/variables';
@import 'src/scss/mixins/card';
@import 'src/scss/mixins/text';

.other-preorders-table {
  @include card();
  gap: 20px;
  grid-column: 1 / -1;
  padding: 20px 20px 8px;
}

.other-preorders-table__title {
  @include textBodyBig-medium();
  margin: 0;
  padding: 0;
}

.other-preorders-table__table {
  border-spacing: 0;
  gap: 25px;
}

.other-preorders-table__row {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: 70px 1fr 220px 100px 74px 116px;
  padding: 12px 0;
}

.other-preorders-table__row_bordered {
  border-top: 1px solid $color-border-gray;
}

.other-preorders-table__row .table__head-cell {
  line-height: 24px;
  word-break: break-word;
}

.other-preorders-table__table-cell {
  @include textBodySmall();
  padding: 0;
  word-break: break-word;
}

.other-preorders-table__table-cell_client {
  display: grid;
  gap: 4px;
}

.other-preorders-table__table-cell_service-place {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 12px 200px;
}

.other-preorders-table__table-cell_phone {
  color: $color-black-50;
}
