@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.radiobox {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.radiobox__input {
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.radiobox__label {
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-template-columns: 20px max-content;
  white-space: nowrap;
}

.radiobox__label_nolabel {
  grid-template-columns: 20px;
}

.radiobox__icon {
  background-color: $color-black-0;
  border: 1px solid $color-black-20;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  position: relative;
  width: 20px;

  &::before {
    background-color: transparent;
    border-radius: 50%;
    content: '';
    height: 24px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    z-index: -1;
  }
}

.radiobox:hover:not(.radiobox_disabled) {
  .radiobox__icon {
    border-color: $color-border-gray-hover;
  }

  &.radiobox_checked .radiobox__icon {
    border-color: $color-theme-accent;
  }
}

.radiobox:focus-within:not(.radiobox_disabled) {
  .radiobox__icon {
    border-color: $color-border-gray-hover;
  }

  .radiobox__icon::before {
    background-color: rgba(14, 141, 82, 0.12);
  }

  &.radiobox_checked .radiobox__icon {
    border: 5px solid $color-theme-accent;

    &:before {
      background: transparent;
    }
  }
}

.radiobox__icon::after {
  background-color: $color-black-20;
  border-radius: 50%;
  content: '';
  display: block;
  height: 8px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
}

.radiobox_checked .radiobox__icon {
  background-color: $color-black-5;
  border: 6px solid $color-theme-main;
}

.radiobox_disabled {
  .radiobox__label {
    color: $color-black-20;
    cursor: default;
  }

  .radiobox__icon {
    background-color: $color-black-5;
    border: 1px solid $color-black-10;
  }

  &.radiobox_checked .radiobox__icon::after {
    opacity: 1;
  }
}

.radiobox_invalid:not(.radiobox_disabled) {
  .radiobox__icon {
    border: 1px solid $color-alert-light;
  }

  &:hover {
    .radiobox__icon {
      border-color: $color-alert;
    }

    &.radiobox_checked .radiobox__icon {
      border-color: $color-alert-light;
    }
  }

  &:focus-within {
    .radiobox__icon {
      border-color: $color-alert;
    }

    .radiobox__icon::before {
      background-color: $color-black-10;
    }

    &.radiobox_checked .radiobox__icon {
      border: 6px solid $color-alert-light;

      &:before {
        background: transparent;
      }
    }
  }

  &.radiobox_checked .radiobox__icon {
    border: 6px solid $color-alert-light;
  }
}
