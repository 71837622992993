@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.order-status-badge {
  @include textCaption();
  background-color: rgba($color-status-default, 0.12);
  border: 1px solid $color-status-default;
  border-radius: 2px;
  color: $color-status-default;
  display: inline-block;
  padding: 1px 7px;
}

.order-status-badge_draft {
  background-color: rgba($color-status-draft, 0.12);
  border-color: $color-status-draft;
  color: $color-status-draft;
}

.order-status-badge_ready {
  background-color: rgba($color-status-ready, 0.12);
  border-color: $color-status-ready;
  color: $color-status-ready;
}

.order-status-badge_signed {
  background-color: rgba($color-status-signed, 0.12);
  border-color: $color-status-signed;
  color: $color-status-signed;
}

.order-status-badge_paid {
  background-color: rgba($color-status-paid, 0.12);
  border-color: $color-status-paid;
  color: $color-status-paid;
}

.order-status-badge_processing {
  background-color: rgba($color-status-processing, 0.12);
  border-color: $color-status-processing;
  color: $color-status-processing;
}

.order-status-badge_finished {
  background-color: rgba($color-status-finished, 0.12);
  border-color: $color-status-finished;
  color: $color-status-finished;
}

.order-status-badge_canceled {
  background-color: rgba($color-status-canceled, 0.12);
  border-color: $color-status-canceled;
  color: $color-status-canceled;
}
