@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.button {
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  outline: none;
  position: relative;
}

.button_filled {
  @include textButton();
  background-color: $color-theme-main;
  border: 0;
  border-radius: 4px;
  color: $color-black-5;
  padding: 12px;
  transition: all ease-in-out $transition-time;

  &:hover:not(:disabled) {
    background-color: $color-theme-accent;
  }

  &:focus:not(:disabled) {
    box-shadow: $button-focus;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:disabled) {
    box-shadow: $button-focus;
  }

  &:active:not(:disabled) {
    background-color: $color-theme-dark;
  }
}

.button_borderless {
  @include textButton();
  background: $color-black-0;
  border: 1px solid $color-black-0;
  border-radius: 4px;
  color: $color-black-75;
  padding: 11px 12px;
  transition: border-color $transition-time ease-in-out;

  &:hover:not(:disabled) {
    border-color: $color-theme-accent;
  }

  &:focus:not(:disabled) {
    box-shadow: $button-focus;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:disabled) {
    box-shadow: $button-focus;
  }

  &:active:not(:disabled) {
    border-color: $color-theme-dark;
  }
}

.button_outlined {
  @include textButton();
  background: $color-black-0;
  border: 1px solid $color-black-20;
  border-radius: 4px;
  color: $color-black-75;
  padding: 11px 12px;
  transition: border-color $transition-time ease-in-out;

  &:hover:not(:disabled) {
    border-color: $color-theme-accent;
  }

  &:focus:not(:disabled) {
    box-shadow: $button-focus;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:disabled) {
    box-shadow: $button-focus;
  }

  &:active:not(:disabled) {
    border-color: $color-theme-dark;
  }
}

.button_flattened {
  @include textButton-flattened();
  padding-bottom: 7px;
  padding-top: 7px;
}

.button_filled.button_flattened {
  color: $color-black-5;
  padding-bottom: 8px;
  padding-top: 8px;
}

.button_disabled {
  cursor: default;
}

.button_borderless.button_disabled,
.button_filled.button_disabled,
.button_outlined.button_disabled {
  background: $color-black-5;
  border-color: $color-black-5;
  color: $color-black-20;
}

.button__icon {
  left: 12px;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.button_flattened .button__icon {
  left: 11px;
}
