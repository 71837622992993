@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.payment-status-badge {
  @include textCaption();
  border-radius: 2px;
  box-sizing: border-box;
  color: $color-status-default;
  display: inline-block;
  padding: 2px 7px;
}

.payment-status-badge_paid {
  background-color: $color-payment-paid-bg;
  color: $color-payment-paid-text;
}

.payment-status-badge_unpaid {
  background-color: $color-payment-unpaid-bg;
  color: $color-payment-unpaid-text;
}

.payment-status-badge_accepted {
  background-color: $color-payment-accepted-bg;
  color: $color-payment-accepted-text;
}
