@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.link-button {
  @include textBodySmall();
  background: transparent;
  border: none;
  box-sizing: border-box;
  color: $color-theme-main;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: color ease-in-out $transition-time;
}

.link-button:not(.link-button_disabled):hover {
  color: $color-theme-dark;
}

.link-button_disabled {
  color: $color-black-20;
  cursor: default;
  pointer-events: none;
}
