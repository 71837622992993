@import 'src/scss/variables';
@import 'src/scss/mixins/text';
@import 'src/scss/mixins/card';

.payment-page {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 18px;
  grid-template-columns: $page-content-width;
  height: 100vh;
  justify-content: center;
  overflow: auto;
  padding-bottom: 60px;
  padding-top: 40px;
}

.payment-page__top {
  @include card();
  padding: 20px;
}

.payment-page__header {
  border-bottom: 1px solid $color-black-10;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding-bottom: 20px;
}

.payment-page__header-title {
  display: grid;
  margin: 0;
}

.payment-page__header-title_right {
  text-align: right;
}

.payment-page__header-text {
  @include textBodySmall();
}

.payment-page__header-data {
  @include textBodyBig-medium();
  word-break: break-word;
}

.payment-page__header-price {
  @include textH3-bold();
}
