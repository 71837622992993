@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.placeholder {
  @include textBodySmall();
  align-content: center;
  bottom: 0;
  color: $color-black-50;
  display: grid;
  height: $input-height;
  left: 12px;
  pointer-events: none;
  position: absolute;
  z-index: 5;
}

.placeholder_flattened {
  height: $input-flattened-height;
}

.placeholder_disabled {
  color: $color-black-20;
}
