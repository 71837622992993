@import 'src/scss/mixins/text';

.text-error {
  @include textCaption();
  bottom: -16px;
  color: $color-red-light;
  display: block;
  line-height: 16px;
  position: absolute;
  right: 0;
  text-align: right;
}
