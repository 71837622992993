@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.payment-page-documents {
  display: grid;
  gap: 16px;
  padding-top: 18px;
}

.payment-page-documents__title {
  @include textBody-medium();
  margin: 0;
}
