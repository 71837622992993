@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.document-item {
  align-content: start;
  display: grid;
  gap: 6px;
}

.document-item__main-info {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 12px;
  grid-template-columns: 20px 1fr 20px;
}

.document-item__icon {
  color: $color-theme-main;
  height: 20px;
  width: 20px;
}

.document-item__title {
  @include textBodySmall();
  grid-column: 2 / 3;
  word-break: break-word;

  &::first-letter {
    text-transform: uppercase;
  }
}

.document-item__subtitle {
  @include textCaption();
  color: $color-black-50;
  padding-left: 32px;
  padding-right: 32px;
  word-break: break-word;
}
