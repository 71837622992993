@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.comment-input {
  background-color: $color-black-0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);
  display: grid;
  gap: 15px 32px;
  grid-template-columns: 1fr 216px;
  margin: 0;
  padding: 20px;
  position: relative;
}

.comment-input__add-icon {
  color: $color-black-75;
}

.button-icon_disabled .comment-input__add-icon {
  color: $color-black-20;
}
