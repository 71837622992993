@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.agreement-meta {
  align-items: center;
  display: grid;
  gap: 14px;
  grid-auto-flow: column;
  justify-content: space-between;
}

.agreement-meta__statuses {
  align-items: center;
  display: grid;
  gap: 24px;
  grid-auto-flow: column;
}

.agreement-meta__actions {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: auto 48px;
}

.agreement-meta__action-button {
  width: 276px;
}

.agreement-meta__menu-toggle {
  border: 1px solid transparent;
  border-radius: 4px;
  height: 48px;
  transition: border-color $transition-time;
  width: 48px;
}

.agreement-meta__menu-toggle:hover,
.agreement-meta__menu-toggle_active {
  border-color: $color-theme-dark;
}
