@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.order-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-list__item {
  margin: 0;
  padding: 0 16px;
  transition: background-color ease-in-out $transition-time;

  &:hover {
    background-color: $color-black-5;
  }
}

.order-list__link {
  border-bottom: 1px solid $color-border-gray;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 140px;
  padding: 10px 4px;
  text-decoration: none;
}

.order-list__cell {
  display: grid;
}

.order-list__label {
  @include textCaption();
  color: $color-black-50;
}

.order-list__value {
  @include textBodySmall();
}
