// FONT FAMILY
$font-main: 'Rubik', sans-serif;

// FONT SIZES
$font-size-tiny: 12px;
$font-size-small: 14px;
$font-size-regular: 16px;
$font-size-big: 20px;
$font-size-h3: 24px;
$font-size-h2: 32px;
$font-size-h1: 48px;

// FONT WEIGHTS
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// THEME COLORS
$color-theme-main: #02abef;
$color-theme-light: #4cbeec;
$color-theme-dark: #007cae;
$color-theme-accent: #009ddc;
$color-theme-background: #ace6fd;
$color-outlined: #35cdfd;
$color-theme-ultra-light: #d4effa;

// GRAY PALETTE
$color-black: #000000;
$color-black-75: #3b3b3b;
$color-black-50: #808080;
$color-black-25: #c4c4c4;
$color-black-20: #cdcdcd;
$color-black-10: #e5e5e5;
$color-black-5: #f7f7f7;
$color-black-2: #fafafa;
$color-black-0: #ffffff;

// COLORS
$color-red: #d95151;
$color-red-light: #f9919a;
$color-alert: #e93147;
$color-alert-light: #f58491;
$color-caption: #b9b9b9;

// TEXT COLORS
$color-text: $color-black-75;
$color-text-disabled: #d9d9d9;
$color-text-contrast: $color-black-5;
$color-text-red: $color-red;

// BORDER COLORS
$color-border-gray: $color-black-10;
$color-border-gray-hover: $color-caption;

// COMPONENTS COLORS
$color-input-focus: rgba(59, 59, 59, 0.1);
$color-input-outline: #35cdfd;
$color-checkbox-focus: rgba(14, 141, 82, 0.12);
$color-checkbox-focus-invisible: rgba(14, 141, 82, 0);
$color-tab-hover: #edf4f6;
$button-focus: 0 0 0 1px $color-black-0, 0 0 0 3px $color-outlined;
$box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 2px 8px rgba(0, 0, 0, 0.06);
$popup-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.18);

// ALERT COLORS
$color-alert-error: $color-red;
$color-alert-error-background: #fdf6f6;
$color-alert-info: #3179bc;
$color-alert-info-background: #f5f8fc;
$color-alert-warning: #67b378;
$color-alert-warning-background: #f3f9f4;

// STATUS COLORS
$color-status-ready: #fc983c;
$color-status-draft: #5f9d9f;
$color-status-signed: #469cd8;
$color-status-paid: #d09600;
$color-status-processing: #fe8870;
$color-status-finished: #c174c2;
$color-status-canceled: #d8c2c2;
$color-status-default: $color-text;

// PAYMENT STATUS COLORS
$color-payment-accepted-text: #7a8271;
$color-payment-accepted-bg: #e8f2db;
$color-payment-paid-text: #676247;
$color-payment-paid-bg: #fff8d1;
$color-payment-unpaid-text: $color-black-50;
$color-payment-unpaid-bg: $color-black-5;

// PREORDER STATUS COLORS
$color-preorder-status-draft: #5b7282;
$color-preorder-status-won: #fe8870;
$color-preorder-status-waiting: #067a91;
$color-preorder-status-confirming: #3c7d0e;
$color-preorder-status-lost: #93a3ad;

// TABLE ROW COLORS
$color-row-added: #ecf5ee;
$color-row-added-hover: #dcf0e1;
$color-row-deleted: #fdebed;

// UI INPUT
$input-height: 48px;
$input-flattened-height: 32px;

// COMPONENTS
$page-header-height: 76px;
$page-content-width: 960px;

// TRANSITION TIME
$transition-time: 0.15s;
$popup-transition-time: 0.5s;
