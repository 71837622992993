@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.dropbox-item {
  margin: 0;
  padding: 0;
}

.dropbox-item__button {
  @include textBodySmall();
  align-items: center;
  background-color: $color-black-0;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  gap: 8px;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  text-align: left;
  transition: background-color $transition-time;
  width: 100%;
}

.dropbox-item__button:hover,
.dropbox-item__button:focus {
  background-color: rgba($color: $color-theme-main, $alpha: 0.1);
  border-radius: 4px;
}

.dropbox-item__icon {
  height: 20px;
  width: 20px;
}
