@import 'src/scss/mixins/text';

.search-orders-page {
  display: grid;
  grid-template-rows: $page-header-height max-content;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.search-orders-page__content {
  display: grid;
  gap: 28px;
  grid-template-columns: 960px;
  grid-template-rows: 28px 1fr;
  justify-content: center;
  overflow: auto;
  padding: 40px 0;
}

.search-orders-page__title {
  @include textBodyBig();
  margin: 0;
}

.search-orders-page__message {
  @include textBody();
  color: $color-caption;
  padding-top: 20px;
  padding-bottom: 16px;
  text-align: center;
}

.search-orders-page__content_empty {
  grid-template-rows: 60px;
}
