@import 'src/scss/variables';
@import 'src/scss/mixins/text';
@import 'src/scss/mixins/card';

.document-tab-view {
  padding-top: 40px;
}

.document-tab-view__wrapper {
  @include card();
  align-content: start;
  gap: 20px;
  padding: 20px;
}

.document-tab-view__title {
  @include textBodyBig-medium();
  margin: 0;
}

.document-tab-view__divider {
  background-color: $color-black-20;
  height: 1px;
}

.document-tab-view__uploaded-files {
  align-content: start;
  display: grid;
  gap: 20px;
}

.document-tab-view__add-button {
  width: 360px;
}
