.info-tab-view {
  display: grid;
  gap: 20px;
}

.info-tab-view__row {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
