@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.agreement-header {
  align-items: center;
  background: $color-black-0;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  display: grid;
  gap: 24px;
  grid-template-columns: auto 1fr;
  height: $page-header-height;
  padding: 10px 32px;
}

.agreement-header__title {
  @include textH3-medium();
  margin: 0;
  padding: 0;
}

.agreement-header__close-button {
  justify-self: end;
}
