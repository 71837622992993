@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.documents-list {
  align-content: start;
  display: grid;
  gap: 22px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.documents-list__item {
  margin: 0;
  padding: 0;
}
