@import 'src/scss/variables';
@import 'src/scss/mixins/text';
@import 'src/scss/mixins/card';

.agreement-tab-view {
  padding-top: 40px;
}

.agreement-tab-view__wrapper {
  @include card();
  box-sizing: border-box;
  display: grid;
  gap: 22px;
  padding: 20px 0;
}

.agreement-tab-view__header {
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 215px;
  padding-left: 20px;
  padding-right: 20px;
}

.agreement-tab-view__title {
  @include textBodyBig-medium();
  margin: 0;
}

.agreement-tab-view__table {
  border-collapse: collapse;
  border-spacing: 0;
  display: grid;
}

.agreement-tab-view__table-body {
  position: relative;
}

.agreement-tab-view__table-row {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  gap: 24px;
  grid-template-columns: 120px 100px 144px 96px 128px 124px 62px;
  outline: none;
  padding: 22px 20px;
  transition: background-color $transition-time;

  &:hover {
    background-color: rgba($color: $color-theme-main, $alpha: 0.1);
  }

  &:focus {
    box-shadow: $button-focus;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: $button-focus;
  }
}

.agreement-tab-view__table-row_head {
  cursor: default;
  padding-bottom: 13px;
  padding-top: 13px;

  &:hover {
    background-color: transparent;
  }
}

.agreement-tab-view__table-row_no-data {
  cursor: default;
  grid-template-columns: 1fr;
  text-align: center;

  &:hover {
    background-color: transparent;
  }
}

.agreement-tab-view__table-cell {
  @include textBodySmall();
  padding: 0;
}

.agreement-tab-view__table-cell_head {
  color: $color-black-50;
  text-align: left;
}

.agreement-tab-view__table-cell_sum {
  text-align: right;
}

.agreement-tab-view__table-cell_actions {
  display: flex;
  justify-content: space-between;
}

.agreement-tab-view__add-button {
  .button__icon {
    transform: translateY(-50%) rotate(45deg);
  }
}
