@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.file-uploader {
  display: grid;
  gap: 20px;
  position: relative;
}

.file-uploader__browse {
  align-items: center;
  background-color: rgba($color: $color-theme-light, $alpha: 0.08);
  border: 1px dashed $color-theme-main;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 28px;
  position: relative;
}

.file-uploader__browse-text {
  @include textBody-light();
  box-sizing: border-box;
  padding-left: 40px;
  width: 330px;
}

.file-uploader__browse-icon {
  color: $color-theme-main;
  height: 24px;
  width: 24px;
}

.file-uploader__browse-input {
  display: none;
}

.file-uploader__browse_disabled {
  cursor: default;
}
