@import 'src/scss/variables';
@import 'src/scss/mixins/image-button';

.uploadable-attachment {
  position: relative;

  &:hover,
  &:focus-within {
    z-index: 2;

    .uploadable-attachment__button-remove {
      opacity: 1;
      visibility: visible;
    }

    .uploadable-attachment__image-attachment,
    .document-attachment {
      opacity: 0.5;
    }
  }
}

.uploadable-attachment__image-attachment {
  @include image-button();
  height: 48px;
  transition: opacity $transition-time;
  width: 48px;
}

.uploadable-attachment__button-remove {
  opacity: 0;
  position: absolute;
  right: -9px;
  top: -10px;
  transition: all $transition-time;
  visibility: hidden;
}
