@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.preorder-tab-view {
  display: grid;
  gap: 20px;
}

.preorder-tab-view__header {
  display: grid;
  gap: 4px;
  grid-template-rows: 28px 20px;
}

.preorder-tab-view__header-info {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr max-content max-content;
}

.preorder-tab-view__header-title,
.preorder-tab-view__header-amount {
  @include textBodyBig-medium();
}

.preorder-tab-view__header-label,
.preorder-tab-view__header-meta {
  @include textBodySmall();
  color: $color-black-50;
}

.preorder-tab-view__row {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}