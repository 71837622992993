@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.service-cell {
  display: grid;
  gap: 3px;
}

.service-cell__attributes-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}

.service-cell__name {
  @include textBodySmall();
  word-break: break-word;
}

.service-cell__attribute {
  @include textCaption();
  color: $color-black-50;
  word-break: break-word;
}

.service-cell__divider {
  background-color: $color-black-50;
  display: inline-block;
  height: 13px;
  width: 1px;
}
