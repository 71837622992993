@import 'src/scss/variables';
@import 'src/scss/mixins/image-button';

.thumb-item {
  opacity: 0.3;
  padding-right: 13px;
  transition: opacity $transition-time, transform $transition-time;
}

.thumb-item__button {
  @include image-button();
  height: 64px;
  width: 90px;
}

.thumb-item_active {
  opacity: unset;

  .thumb-item__button {
    cursor: unset;
  }
}
