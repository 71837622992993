@import 'src/scss/variables';
@import 'src/scss/mixins/card';
@import 'src/scss/mixins/text';

.agreement-documents {
  @include card();
  gap: 24px;
  padding: 20px;
}

.agreement-documents__title {
  @include textBodyBig-medium();
  margin: 0;
  padding: 0;
}
